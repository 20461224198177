<template>
  <v-select
    outlined
    v-model="metrics"
    :items="metricList"
    :menu-props="{ maxHeight: '400', zIndex: 13 }"
    label="Métrique"
    multiple
    item-text="name"
    item-value="id"
  ></v-select>
</template>

<script>
import isEqual from "lodash/isEqual";
import {
  computed,
  onMounted,
  reactive,
  set,
  watch,
} from "@vue/composition-api";

import useProxy from "@/hooks/useProxy";
import useQueryString from "@/hooks/useQueryString";

import { URL_PARAM_NAMES } from "@/utils/constants";

const COMPONENT_NAME = "MetricFilter";

/**
 * Either call this component giving an `initialValue` (int[])
 *  and listening on `valueChanged` event,
 * Or call it specifying a `store`
 *  (and eventually `storeGetter`/`storeUpdater`)
 */
export default {
  name: COMPONENT_NAME,
  props: {
    // initialValue: {
    //   type: Array,
    //   validator(values) {
    //     return (
    //       values.length === 0 ||
    //       values.every((value) => typeof value === "number")
    //     );
    //   },
    // },
    store: {
      type: String,
      validator(value) {
        /* List of stores having :
        - getters["<store>/<storeGetter>"]
        - dispatch("<store>/<storeUpdater>", payload)
        */
        return ["webPerf"].includes(value);
      },
    },
    storeGetter: {
      type: String,
      default: "getMetrics",
    },
    storeUpdater: {
      type: String,
      default: "updateMetrics",
    },
  },
  setup(props, { emit }) {
    const { store } = useProxy();

    const { addQueryStringParam, synchronizeFilterWithQueryString } =
      useQueryString();

    const page = reactive({
      isInitialized: false,
      initialUrlValue: [],
    });

    // =>  METHODS

    // =>  COMPUTED
    const viaStore = computed(() => props.store !== undefined);
    const metrics = computed({
      get() {
        if (viaStore.value) {
          return store.getters[`${props.store}/${props.storeGetter}`];
        }

        return page.initialUrlValue; // ?? this.initialValue;
      },
      set(e) {
        if (viaStore.value === false) {
          if (!isEqual(e, pge.initialUrlValue /*?? this.initialValue*/)) {
            emit("valueChanged", e);
          }

          return;
        }

        store.dispatch(`${props.store}/${props.storeUpdater}`, e);
      },
    });

    // =>  WATCH
    watch(metrics, (newValue) => {
      addQueryStringParam({
        param: URL_PARAM_NAMES[COMPONENT_NAME],
        value: newValue,
        is_multiple: true,
        // is_integer: false,
        // is_boolean: false,
      });
    });

    // =>  HOOKS
    onMounted(async () => {
      set(
        page,
        "initialUrlValue",
        await synchronizeFilterWithQueryString({
          dispatcher: viaStore.value
            ? `${props.store}/${props.storeUpdater}`
            : "",
          param: URL_PARAM_NAMES[COMPONENT_NAME],
          value: metrics.value,
          is_multiple: true,
          // is_integer: false,
          // is_boolean: false,
          // dependsOn: undefined,
        })
      );

      page.isInitialized = true;
    });

    // =>  DATA
    return {
      metrics,
      metricList: [
        { id: "cls", name: "CLS" },
        { id: "fcp", name: "FCP" },
        { id: "inp", name: "INP" },
        { id: "lcp", name: "LCP" },
        { id: "ttfb", name: "TTFB" },
      ],
    };
  },
};
</script>

<style scoped>
[role="menu"] {
  top: 233px !important;
  right: 0 !important;
}
</style>
