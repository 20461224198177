export const ranges = {
  LCP: [2500, 4000],
  INP: [200, 500],
  CLS: [0.1, 0.25],
  FCP: [1800, 3000],
  TTFB: [800, 1800],
};

export const formatLcp = (value) => {
  return `${(value / 1000).toFixed(2)}s`;
};

export const formatInp = (value) => {
  return `${value}ms`;
};

export const formatCls = (value) => {
  return `${value.toFixed(2)}`;
};

export const formatFcp = (value) => {
  return `${(value / 1000).toFixed(2)}s`;
};

export const formatTtfb = (value) => {
  return `${Math.round(value)}ms`;
};

export const formatCwvMetric = (value, type) => {
  switch (type) {
    case "LCP":
      return formatLcp(value);
    case "INP":
      return formatInp(value);
    case "CLS":
      return formatCls(value);
    case "FCP":
      return formatFcp(value);
    case "TTFB":
      return formatTtfb(value);
    default:
      return value;
  }
};
