<template>
  <v-autocomplete
    outlined
    v-model="pageTypes"
    :items="pageTypeList"
    :menu-props="{ maxHeight: '400', zIndex: 13 }"
    label="Type de page"
    multiple
    item-text="name"
    item-value="id"
  ></v-autocomplete>
</template>

<script>
import isEqual from "lodash/isEqual";
import {
  computed,
  onMounted,
  reactive,
  set,
  watch,
} from "@vue/composition-api";

import useProxy from "@/hooks/useProxy";
import useQueryString from "@/hooks/useQueryString";

import { URL_PARAM_NAMES } from "@/utils/constants";

const COMPONENT_NAME = "PageTypeFilter";

/**
 * Either call this component giving an `initialValue` (int[])
 *  and listening on `valueChanged` event,
 * Or call it specifying a `store`
 *  (and eventually `storeGetter`/`storeUpdater`)
 */
export default {
  name: COMPONENT_NAME,
  props: {
    // initialValue: {
    //   type: Array,
    //   validator(values) {
    //     return (
    //       values.length === 0 ||
    //       values.every((value) => typeof value === "number")
    //     );
    //   },
    // },
    store: {
      type: String,
      validator(value) {
        /* List of stores having :
        - getters["<store>/<storeGetter>"]
        - dispatch("<store>/<storeUpdater>", payload)
        */
        return ["webPerf"].includes(value);
      },
    },
    storeGetter: {
      type: String,
      default: "getPageTypes",
    },
    storeUpdater: {
      type: String,
      default: "updatePageTypes",
    },
  },
  setup(props, { emit }) {
    const { store } = useProxy();

    const { addQueryStringParam, synchronizeFilterWithQueryString } =
      useQueryString();

    const page = reactive({
      isInitialized: false,
      initialUrlValue: [],
    });

    // =>  METHODS

    // =>  COMPUTED
    const viaStore = computed(() => props.store !== undefined);
    const pageTypes = computed({
      get() {
        if (viaStore.value) {
          return store.getters[`${props.store}/${props.storeGetter}`];
        }

        return page.initialUrlValue; // ?? this.initialValue;
      },
      set(e) {
        if (viaStore.value === false) {
          if (!isEqual(e, page.initialUrlValue /*?? this.initialValue*/)) {
            emit("valueChanged", e);
          }

          return;
        }

        store.dispatch(`${props.store}/${props.storeUpdater}`, e);
      },
    });

    const pageTypeList = computed(
      () => store.getters["webPerf/getPageTypeList"]
    );

    // =>  WATCH
    watch(pageTypes, (newValue) => {
      if (page.isInitialized) {
        addQueryStringParam({
          param: URL_PARAM_NAMES[COMPONENT_NAME],
          value: newValue,
          is_multiple: true,
          // is_integer: false,
          // is_boolean: false,
        });
      }
    });

    // =>  HOOKS
    onMounted(async () => {
      await store.dispatch("webPerf/getPageTypeList");

      set(
        page,
        "initialUrlValue",
        await synchronizeFilterWithQueryString({
          dispatcher: viaStore.value
            ? `${props.store}/${props.storeUpdater}`
            : "",
          param: URL_PARAM_NAMES[COMPONENT_NAME],
          value: pageTypes.value,
          is_multiple: true,
          // is_integer: false,
          // is_boolean: false,
          // dependsOn: undefined,
        })
      );

      page.isInitialized = true;
    });

    // =>  DATA
    return {
      pageTypes,
      pageTypeList,
    };
  },
};
</script>

<style scoped>
[role="menu"] {
  top: 233px !important;
  right: 0 !important;
}
</style>
